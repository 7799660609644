import React, { useEffect, useRef, useState } from "react";
import "./dicusion.scss";
import mintClient from "../../../network";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import { setRequestGlobalLoader } from "../../../store/slices/requestGlobalLoader/actions";
import Discusion from "../../../commons/models/Discusion";
import CardDiscusion from "./CardDiscusion";
import Page from "../../../layout/PageWrapper/Page";
import Navnav from "../../Navnav/Navnav";
import Input from "../../../components/bootstrap/forms/Input";
import Spinner from "../../../components/bootstrap/Spinner";
import PaginationComponent from "../../../components/PaginationComponent";
import { globalT } from "../../../lang";
import InputComponent from "../../../components/forms/InputComponent";
import IntlMessages from "../../../components/IntlMessages";
import * as yupSchema from "../../../components/forms/yupShema";
import Button from "../../../components/bootstrap/Button";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import StorageHelper, {
  StorageKeys,
} from "../../../commons/helpers/StorageHelper";
import { SubmitHandler, useForm } from "react-hook-form";
import { messagePhone } from "../../../store/slices/MessagePhone/actions";
import csuClient from "../../../network";
import * as yup from "yup";
import * as YupShema from "../../../components/forms/yupShema";
import { yupResolver } from "@hookform/resolvers/yup";
import LANG from "../../../lang/language";
import Header from "../Header";
import Footer from "../Footer";
import newImage from "../../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../../components/ImageSensibilisation";
import {useLocation, useNavigate} from "react-router-dom";

const schema = yup.object().shape({
  // phone: YupShema.name,
  lastName: YupShema.name,
});
const IndexChat = () => {
  const [dataDiscussion, setDataDiscussion] = useState<null | Discusion[]>(
    null
  );
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(true);
  const dataUsager = useRef(
    JSON.parse(StorageHelper.getItem(StorageKeys.USER_USAGER) as string)
  );
  const isAdministrator = useRef(false);
  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );

  const navigate = useNavigate()


  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    // resolver: yupResolver(schema),
  });

  const loadDiscusion = () => {
    setLoading(true);
    mintClient.discusion
      .getAll({
        page: page,
        pageSize: 10,
        orderDirection: "ascending",
        researchValue: search,
        hasPagination: true,
      })
      .then((resp) => {
        setDataDiscussion(resp.data.data);
        setLoading(false);
      })
      .catch(() => null)
      .finally(() => dispatch(setRequestGlobalLoader(false)));
  };

  const verifModerator = async (number: string) => {
    await csuClient.moderateur
      .activer(number)
      .then(() => {
        isAdministrator.current = true;
      })
      .catch((errors) => {
        if (errors.response.data.message === "Moderator already activated") {
          isAdministrator.current = true;
        } else isAdministrator.current = false;
      });
  };

  const verifIsAdmin = async () => {
    if (dataUsager.current) {
      await verifModerator(dataUsager.current.phone);
      StorageHelper.setItem(
        StorageKeys.USER_USAGER,
        JSON.stringify({
          phone: dataUsager.current.phone,
          name: dataUsager.current.lastName,
          lastName: dataUsager.current.firstName,
          isAdmin: isAdministrator.current,
        })
      );
    }
  };

  useEffect(() => {
    verifIsAdmin();
  }, []);

  const onSubmit: SubmitHandler<any> = async (data) => {
    await verifModerator(data.phone);
    dataUsager.current = {
      phone: data.phone,
      name: data.lastName,
      lastName: data.firstName,
      isAdmin: isAdministrator.current,
    };
    StorageHelper.setItem(
      StorageKeys.USER_USAGER,
      JSON.stringify({
        phone: data.phone,
        name: data.lastName,
        lastName: data.firstName,
        isAdmin: isAdministrator.current,
      })
    );
    dispatch(
      messagePhone({
        phone: data.phone,
        name: data.lastName,
        lastName: data.firstName,
        isAdmin: isAdministrator.current,
      })
    );
    setShowModal(false);
  };

    useEffect(() => {
        window.scrollTo({
            top: 20,
            behavior: "smooth",
        });
    }, []);

  useEffect(() => {
    loadDiscusion();
  }, [search, page]);

  return (
    <>
      <Header currentMenu={"acceuil"} />
      <div className="container" id={"sectionId"}>
        <ImageSensibilisation
          image={newImage}
          texte="Forum du ministère des transports !!!"
        />{" "}
      </div>
      <Page>
        <div className="discu-container">
          <div className="discu-header d-flex justify-content-between mb-5">
            <h2>{globalT("all.discuss")}</h2>
            <Input
              type="search"
              id="searchInput"
              autoComplete="off"
              value={search}
              placeholder={globalT("search.education")}
              className="shadow-none border"
              style={{ paddingLeft: "30px", width: "28rem" }}
              onChange={(e: any) => {
                setLoading(true);
                setSearch(e.target.value);
              }}
            />
          </div>
          <div className="discu-content row">
            {dataDiscussion && dataDiscussion.length > 0 && (
              <>
                {dataDiscussion.map((d) => (
                  <div className="col-4">
                    <CardDiscusion
                      id={d.id}
                      name={
                        settings.language === LANG.fr.locale
                          ? d.name
                          : d.nameEn
                          ? d.nameEn
                          : d.name
                      }
                      nbreMessage={d.totalMessage}
                      description={
                        settings.language === LANG.fr.locale
                          ? d.name
                          : d.descriptionEn
                          ? d.descriptionEn
                          : d.description
                      }
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          {loading && <Spinner color="primary" size="3rem" />} <br />
          <PaginationComponent
            pages={Math.ceil((dataDiscussion?.length || 0) / 10)}
            currentPage={page}
            onPageChange={(page) => setPage(page)}
            rangeLimit={5}
          />
        </div>
      </Page>
      {!dataUsager.current && (
        <DialogComponent
          show={showModal}
          title={globalT("form.forum.message.phone")}
          onClose={() => setShowModal(false)}
          isIconText={true}
          isStaticBackdrop
        >
          <form>
            <InputComponent
              errors={errors}
              control={control}
              displayRequiredAsterisk
              isRequired={true}
              name="lastName"
              label={<IntlMessages id="form.field.lastName" />}
              otherValidators={{
                validate: (val: string | undefined) =>
                  !val
                    ? true
                    : !yupSchema.regex.name.test(val || "") || (
                        <IntlMessages id="form.errors.forum.name.cmr" />
                      ),
              }}
            />
            <InputComponent
              errors={errors}
              control={control}
              name="firstName"
              label={<IntlMessages id="form.field.firstName" />}
              otherValidators={{
                validate: (val: string | undefined) =>
                  !val
                    ? true
                    : !yupSchema.regex.name.test(val || "") || (
                        <IntlMessages id="form.errors.forum.name.cmr" />
                      ),
              }}
            />
            <InputComponent
              errors={errors}
              control={control}
              displayRequiredAsterisk
              type="number"
              isRequired={true}
              name="phone"
              label={<IntlMessages id="form.field.phoneNumber" />}
              otherValidators={{
                validate: (val: string | undefined) =>
                  !val
                    ? true
                    : yupSchema.regex.cameroonPhoneNumber.test(val || "") || (
                        <IntlMessages id="form.errors.phone.cmr" />
                      ),
              }}
            />
            <div className="text-end mt-3">
                <Button
                    color="primary"
                    onClick={() => navigate("/")}
                    // iconPosition='right'
                    className="text-nowrap bg-gradient ms-2 bg-black"
                >
                    <IntlMessages id="button.back" />
                </Button>
              <Button
                icon="Save"
                type="submit"
                color="info"
                onClick={handleSubmit(onSubmit)}
                // iconPosition='right'
                className="text-nowrap bg-gradient ms-2 bg-orange"
              >
                <IntlMessages id="button.save" />
              </Button>
            </div>
          </form>
        </DialogComponent>
      )}
      <Footer />
    </>
  );
};

export default IndexChat;
