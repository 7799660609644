import React, { useEffect, useRef, useState } from "react";
import CardImg from "../../components/bootstrap/CardImg";
import DataImage from "../../assets/img/landing2/vide.webp";
import { NavLink, useNavigate } from "react-router-dom";
import { globalT } from "../../lang";
import { useAppDispatch, useAppSelector } from "../../store/redux.types";
import Publication from "../../commons/models/Publication";
import { setRequestGlobalLoader } from "../../store/slices/requestGlobalLoader/actions";
import mintClient from "../../network";
import Communicator from "../../components/Communicator";
import Spinner from "../../components/bootstrap/Spinner";
import { joinUrlWithParamsId } from "../../commons/helpers/funcHelper";
import "./Actualite.scss";
import SearchComponent from "../../components/SearchComponent";
import PaginationComponent from "../../components/PaginationComponent";
import Header from "../acceuill/Header";
import CardImage from "../acceuill/card/CardImage";
import "../acceuill/Home.scss";
import Footer from "../acceuill/Footer";
import newImage from "../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../components/ImageSensibilisation";

const Actualite = () => {
  const navigate = useNavigate();
  const [publication, setPublication] = useState<Publication[] | null>(null);
  const [publicationHeader, setPublicationHeader] = useState<
    Publication[] | null
  >(null);
  const dispatch = useAppDispatch();
  const total = useRef<number>();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );
  const currentSearch = useRef("");

  const loadPublicationHeader = () => {
    // dispatch(setRequestGlobalLoader(true))
    mintClient.publication
      .getAll({
        page: 1,
        pageSize: 6,
        order: true,
        orderDirection: "ascending",
        validationState: 1,
        researchValue: search,
        hasPagination: true,
      })
      .then((resp) => {
        const allPublications = resp.data.data;
        setPublicationHeader(allPublications);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(setRequestGlobalLoader(false)); // Stop global loader in any case
      });
    // Start loading
  };

  const loadPublication = async () => {
    setLoading(true); // Start loading
    await mintClient.publication
      .getAll({
        page: page,
        pageSize: 9,
        order: true,
        orderDirection: "ascending",
        validationState: 1,
        researchValue: search,
        hasPagination: true,
      })
      .then((resp) => {
        const allPublications = resp.data.data;
        total.current = resp.data.total;
        setPublication(allPublications);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  const handleSearch = (value: any) => {
    setSearch(value);
    setPage(1);
    setLoading(true);
  };

  useEffect(() => {
    if (!publicationHeader) loadPublicationHeader();
    loadPublication();
  }, [search, page]);

    useEffect(() => {
        window.scrollTo({
            top: 20,
            behavior: "smooth",
        });
    }, []);

  return (
    <>
      <Header currentMenu={"actualite"} />
      <div className="container"  id={"sectionId"}>
        <ImageSensibilisation
          image={newImage}
          texte="Actualité du ministère des transports"
        />{" "}
      </div>
      <section className="container actualiteContent">
        <div className="d-flex justify-content-sm-between">
          <h1 style={{ fontWeight: "bold" }}>
            {globalT("paragraph.management.list.news")}
          </h1>
          <div className="">
            {loading && publication && (
              <Spinner customColor="#aae0aa" size="3rem" />
            )}{" "}
            <div className="">
              <SearchComponent onSearch={handleSearch} />
            </div>
          </div>
        </div>

        <div className="row py-4">
          {publication && publication.length > 0 ? (
            <>
              <div className="row">
                {publication.map((pub) => (
                  <CardImage
                    name={pub.name}
                    nameEn={pub.nameEn}
                    images={pub.attachement}
                    id={pub.id}
                    categorie={pub.categoryName}
                    createAt={pub.createdAt}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="imageContainer" style={{ marginTop: "100px" }}>
              <div className="text-center mt-5 mb-5">
                <div
                  style={{
                    width: "400px", // or any size you want
                    height: "400px", // make it a square
                    backgroundImage: `url(${DataImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    margin: "0 auto", // this will center the div if the parent div has a text-align: center
                  }}
                  className="image"
                />
                {loading ? (
                  <div className="d-flex justify-content-center spinnerContainer">
                    <div
                      className="spinner-border"
                      style={{
                        height: "6rem",
                        width: "6rem",
                        color: "#aae0aa",
                        marginTop: "85px",
                        marginRight: "15px",
                      }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <span
                    className="fw-bold"
                    style={{
                      marginTop: "20px",
                      fontSize: "24px",
                    }}
                  >
                    {globalT("empty.actualite")}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <PaginationComponent
          pages={Math.ceil((total.current || 0) / 9)}
          currentPage={page}
          onPageChange={(page) => setPage(page)}
          rangeLimit={5}
        />
      </section>

      <Footer />
    </>
  );
};

export default Actualite;
