import React from "react";
import Footfoot from "../Navnav/Footfoot";
import Navnav from "../Navnav/Navnav";
import "../acceuill/Home.scss";
import Header from "./Header";
import newImage from "../../assets/img/landing2/back_copie.png";
import Footer from "./Footer";
import { globalT } from "../../lang";
import ImageSensibilisation from "../../components/ImageSensibilisation";
import Card, {CardBody} from "../../components/bootstrap/Card";
import Icon from "../../components/icon/Icon";
import iformation from "../../assets/img/other/information.png"
import presentation from "../../assets/img/other/presentation.png"
import warning from "../../assets/img/other/warning.png"
import compass from "../../assets/img/other/compass.png"
import user from "../../assets/img/other/group-users.png"


const About = () => {
  return (
    <>
      <Header currentMenu={"revue"} />
      <div className="container" id={"sectionId"}>
        <ImageSensibilisation
          image={newImage}
          texte="Apropos du ministère du transport !!!"
        />
      </div>
      {/*   <div className="mb-5 text-center display-6 mt-5">
                About
            </div> */}

      <div className="container" style={{ fontFamily: "sans-serif" }}>
        <div className="fs-1 fw-bold mb-3 text-center" style={{marginTop:"6rem"}}>
          <span>{globalT("pourquoi.apsr")}</span>
        </div>

        <div className="homeAbout">
          <div className="row text-white">
            <div className="col-sm-12 col-md-6">
              <Card className="bg-secondary">
                <CardBody>
                  <div className="d-flex flex-column">
                    <span className="homeAbout__icon"></span>
                    <span className="homeAbout__title">{globalT("informer.usager")}</span>
                    <ul>
                      <li className="mb-3">{globalT("fournir.informations")}</li>
                      <li>{globalT("permettre.utilisateurs")}</li>
                    </ul>
                    <br/>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-sm-12 col-md-6">
              <Card  style={{backgroundColor:"#9E9E9E"}}>
                <CardBody>
                  <div className="d-flex flex-column">
                    <span className="homeAbout__icon"></span>
                    <span className="homeAbout__title">{globalT("faciliter.com")}</span>
                    <ul>
                      <li className="mb-3">{globalT("mettre.place")}</li>
                      <li>{globalT("favo.colla")}</li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-sm-12 col-md-6">
              <Card style={{backgroundColor:"#9E9E9E"}}>
                <CardBody>
                  <div className="d-flex flex-column">
                    <span className="homeAbout__icon"></span>
                    <span className="homeAbout__title">{globalT("promu.forma")}</span>
                    <ul>
                      <li className="mb-3">{globalT("offrir.cont")}</li>
                      <li>{globalT("organiser.camp")}</li>
                    </ul>
                    <br/>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-sm-12 col-md-6">
              <Card className="bg-primary">
                <CardBody>
                  <div className="d-flex flex-column">
                    <span className="homeAbout__icon"></span>
                    <span className="homeAbout__title">{globalT("informer.usager")}</span>
                    <ul>
                      <li className="mb-3">{globalT("fournir.informations")}</li>
                      <li>{globalT("permettre.utilisateurs")}</li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid bg-secondary"
        style={{
          //height: "35vh",
          width: "100%",
          marginTop: "6%",
          marginBottom: "4%",
          fontFamily: "sans-serif",
        }}
      >
        <div style={{ marginLeft: "2%", marginTop: "2%" }}>
          <div className="fs-2 fw-bold ms-4" style={{ color: "white" }}>
            {globalT("fonc.cle")}
          </div>

          <div
            className="fs-3 ms-4"
            style={{ paddingTop: "1%", color: "white" }}
          >
            {globalT("fonc.diff")}
          </div>
        </div>

        <div
          className="row text-center justify-content-center mb-4"
          style={{ marginTop: "3%" }}
        >

          <div className="d-flex flex-column justify-content-center align-items-center col-4 col-sm-4 mb-4 col-md-3 col-lg-2">
            <img src={iformation} alt={"comâss"} style={{ width:"6rem", height: "6rem"}}/>
            <span className="aboutLogoTitle" style={{ color: "white", fontStyle:"1.3rem" }}>{globalT("info.en")}</span>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center col-4 col-sm-4 mb-4 col-md-3 col-lg-2">
            <img src={compass} alt={"comâss"} style={{ width:"6rem", height: "6rem"}}/>
            <span className="aboutLogoTitle" style={{ color: "white", fontStyle:"1.3rem" }}>{globalT("nav.per")}</span>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center col-4 col-sm-4 mb-4 col-md-3 col-lg-2">
            <img src={warning} alt={"comâss"} style={{ width:"6rem", height: "6rem"}}/>
            <span className="aboutLogoTitle" style={{ color: "white", fontStyle:"1.3rem" }}>{globalT("sign.inci")}</span>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center col-4 col-sm-4 mb-4 col-md-3 col-lg-2">
            <img src={presentation} alt={"comâss"} style={{ width:"6rem", height: "6rem"}}/>
            <span className="aboutLogoTitle" style={{ color: "white", fontStyle:"1.3rem" }}>{globalT("form.cont")}</span>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center col-4 col-sm-4 mb-4 col-md-3 col-lg-2">
            <img src={user} alt={"comâss"} style={{ width:"6rem", height: "6rem"}}/>
            <span className="aboutLogoTitle" style={{ color: "white", fontStyle:"1.3rem" }}>{globalT("Communaut")}</span>
          </div>
        </div>
      </div>

      <div
        className="container "
        style={{ fontFamily: "serif", marginBottom: "8%" }}
      >
        <div className="row justify-content-center" style={{ marginTop: "5%" }}>
          <div className="fw-bold w-75 fs-2">{globalT("actua.pub")}</div>

          <div className="row border-apropos">
            <div className="col-12 col-md-4 mb-4">
              <img
                src={newImage}
                className="img-fluid"
                id={"sectionId"}
                alt="imageNew"
                style={{
                  marginTop: "1rem",
                  width: "95%",
                  height: "90%",
                  objectFit: "cover",
                }}
              />
            </div>

            <div className="col-12 col-md-8 text-justify">
              <div className="fs-2 fw-bold">
                {globalT("rest.info")}
              </div>
              <div className="fs-4 mt-2">
                {globalT("dern.nouv")}
                <div>{globalT("com.press")}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center" style={{ marginTop: "5%" }}>
          <div className="fw-bold w-75 fs-2">{globalT("tou.serv")}</div>
          <div className="row border-apropos mt-4 ">
            <div className="col-12 col-md-4 mb-4 mb-4">
              <img
                src={newImage}
                className="img-fluid"
                id={"sectionId"}
                alt="imageNew"
                style={{
                  marginTop: "1rem",
                  width: "95%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />{" "}
            </div>

            <div className="col-12 col-md-8 text-justify">
              <span className="fs-4">{globalT("dem.li")} </span>
              <span className="fs-4">{globalT("perm.cond")}</span>
              <div>
                <span className="fs-4">{globalT("info.pra")}</span>
                <span className="fs-4">{globalT("ho.ad")}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center" style={{ marginTop: "5%" }}>
          <div className="fw-bold fs-2 w-75">{globalT("ado.co")}</div>
          <div className="row border-apropos mt-4">
            <div className="col-12 col-md-4 mb-4 mb-4">
              <img
                src={newImage}
                className="img-fluid"
                id={"sectionId"}
                alt="imageNew"
                style={{
                  marginTop: "1rem",
                  width: "95%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />{" "}
            </div>

            <div className="col-12 col-md-8 text-justify">
              <span className="fs-4">{globalT("med.va")} </span>
              <span className="fs-4">{globalT("file")}</span>
              <div>
                <span className="fs-4">{globalT("theme.abord")}</span>
                <span className="fs-4">{globalT("sec.rout")}</span>
              </div>

              <div>
                <span className="fs-4">{globalT("parto")}</span>
                <span className="fs-4">{globalT("co.co")}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center" style={{ marginTop: "5%" }}>
          <div className="fw-bold fs-2 w-75">{globalT("tout.info")}</div>
          <div className="row border-apropos mt-4">
            <div className="col-12 col-md-4 mb-4">
              <img
                src={newImage}
                className="img-fluid"
                id={"sectionId"}
                alt="imageNew"
                style={{
                  marginTop: "1rem",
                  width: "95%",
                  height: "90%",
                  objectFit: "cover",
                }}
              />{" "}
            </div>

            <div className="col-12 col-md-8 text-justify">
              <span className="fs-4">{globalT("etude")}</span>
              <span className="fs-4"> {globalT("etu.sec")}</span>
              <div>
                <span className="fs-4">{globalT("comm")}</span>
                <span className="fs-4">{globalT("com.pres")}</span>
              </div>

              <div>
                <span className="fs-4">{globalT("acc")}</span>
                <span className="fs-4">{globalT("acc.pat")}</span>
              </div>

              <div>
                <span className="fs-4">{globalT("per.con")}</span>
                <span className="fs-4">{globalT("mod.per")}</span>
              </div>

              <div>
                <span className="fs-4">{globalT("infra")}</span>
                <span className="fs-4">{globalT("code.infra")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default About;
