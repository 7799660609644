import React, { useState } from "react";
import "./Home.scss";
import google from "../../assets/img/landing2/google.png";
import apple from "../../assets/img/landing2/apple.png";
import { globalT } from "../../lang";
import ContactUs from "../../components/ContactUs";
import Button from "../../components/bootstrap/Button";
import { useNavigate } from "react-router-dom";
import chat from "../../assets/img/landing2/nouv/chat2.png";
import Chatbot from "react-chatbot-kit";
import config from "../chatBot/config";
import ActionProvider from "../chatBot/ActionProvider";
import MessageParser from "../chatBot/MessageParser";
import DialogComponent from "../../components/dialogs/DialogComponent";
import FrontAdd from "../Incident/incident/FrontAdd";
import DrawerComponent from "../../components/DrawerComponent";
import DetailFunc from "../../components/DetailFunc";
import ContactAdd from "../NewsLetter/NewsLetterAdd";
import mobileImage from "../../assets/img/landing2/nouv/mobile5.png";
import mobileImage2 from "../../assets/img/landing2/nouv/mobile2.jpeg";
import Icon from "../../components/icon/Icon";
import Forum from "./card/Forum";

const Footer = () => {
  const navigate = useNavigate();
  const [showBot, setShowBot] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [showInfpanel, setShowInfPanel] = useState<boolean>(false);
  const [showContact, setShowContact] = useState<boolean>(false);
  const [isToggled, setIsToggled] = useState(false);

  const toggleIcon = () => {
    setIsToggled(!isToggled);
  };

  const [showBot2, setShowBot2] = useState(false);


  return (
    <div>
      <div className="homeMobile position-relative">
        <div className="homeMobile__imageMobile">
          <img src={mobileImage2} alt="mobile" className="image" />
        </div>
        <div className="homeMobile__imageMobile2">
          <img src={mobileImage2} alt="mobile" className="image" />
        </div>
        <div className="homeMobile__imageMobile3">
          <img src={mobileImage2} alt="mobile" className="image" />
        </div>
        <div className="row h-100">
          <div className="col-8">
            <div className="d-flex flex-column homeMobile__content">
              <span className="homeMobile__title">
                {globalT("landing.connect.platform.title")}
              </span>
              <span className="homeMobile__subtitle">
                {globalT("landing.connect.platform.subtitle")}
              </span>
              <div className="row">
                <img
                  src={apple}
                  alt="google play"
                  className="img-fluid apple col-6"
                />
                <img
                  src={google}
                  alt="google play"
                  className="img-fluid google col-6 text-start"
                />
              </div>
            </div>
          </div>
          <div className="col-4 d-flex justify-content-center align-items-center">
            <div className="z-index-10">
              <img
                src={mobileImage}
                alt=""
                className="img-fluid w-75 z-index-10"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="coordonne">
        <div className="coordonne__content">
          <div className="row">
            <div className="col-lg-7 mb-sm-3 mb-lg-0">
              <div className="d-flex flex-column">
                <span className="coordonne__title">
                  {globalT("nos.coordonnees")}
                </span>
                <span className="coordonne__subtitle">
                  {globalT("applelez.nous")}
                </span>
                <div className="d-flex flex-column">
                  <span>
                    {" "}
                    <span className="">{globalT("footer.number.1")}</span> :
                    (+237) 222 220 547{" "}
                  </span>
                  <span>
                    {" "}
                    <span className="">{globalT("footer.number.2")}</span> : 222
                    220 741{" "}
                  </span>
                  <span>
                    {" "}
                    <span className="">{globalT("footer.number.3")}</span> : 222
                    232 893{" "}
                  </span>
                  <span>
                    {" "}
                    <span className="">{globalT("footer.number.4")}</span> :
                    222.232.893{" "}
                  </span>
                  <span>
                    {" "}
                    <span className="">{globalT("Email")}</span> :
                    mintransportscameroon@yahoo.com{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt-sm-5 mt-lg-0">
              <div className="d-flex flex-column">
                <span className="coordonne__title">{globalT("contactUs")}</span>
                <span className="coordonne__subtitle">
                  {globalT("form.contact")}
                </span>
                <div className="">
                  <ContactUs />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="linkFooter">
        <div className="row">
          <div className="col-md-6 col-lg-3 col-xl-3 mb-sm-3 mb-xl-0">
            <div className="d-flex flex-column">
              <span className="linkFooter__title">
                {globalT("lien.utiles")}
              </span>
              <button
                // color={"light"}
                onClick={() => null}
                className="d-flex buttonFooter"
              >
                <span className="" style={{ fontSize: "1.2rem" }}>
                  {" "}
                  {globalT("home")}
                </span>
              </button>
              <button
                // color={"light"}
                onClick={() => null}
                className="d-flex buttonFooter"
              >
                <span className="" style={{ fontSize: "1.2rem" }}>
                  {globalT("nav.service")}
                </span>
              </button>
              <button
                // color={"light"}
                onClick={() => setShow(true)}
                className="d-flex buttonFooter"
              >
                <span className="" style={{ fontSize: "1.2rem" }}>
                  {globalT("incident.add.title")}
                </span>
              </button>
              <button
                // color={"light"}
                onClick={() => null}
                className="d-flex buttonFooter"
              >
                <span className="" style={{ fontSize: "1.2rem" }}>
                  {globalT("campagne.management")}
                </span>
              </button>
              <button
                // color={"light"}
                onClick={() => null}
                className="d-flex buttonFooter"
              >
                <span className="" style={{ fontSize: "1.2rem" }}>
                  {globalT("footer.document")}
                </span>
              </button>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-4 mb-sm-3 mb-xl-0 text-start">
            <div className="d-flex flex-column">
              <span className="linkFooter__title">CHATBOT</span>

              <span
                className="cursor-pointer"
                onClick={(e) => {
                  setShowBot((prev) => !prev);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {globalT("chatbot.message")}
                <span className="text-underline text-danger">
                  {globalT("chatbot.here")}
                </span>
              </span>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 col-xl-5 mb-sm-3 mb-xl-0">
            <div className="d-flex flex-column">
              <span className="linkFooter__title">{globalT("souscrire")}</span>
              <span>{globalT("contact.info.message")}</span>
              <br />
              <div className="d-flex justify-content-end footer-button-div">
                <Button className="l-bu" onClick={() => setShowContact(true)}>
                  <span>{globalT("button.abonner")}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyrightArea">
        <div className="copyrightArea__line"></div>
        <div className="row">
          <div className="col-md-7">
            <div className="">
              <span>
                Copyright &copy; 2023, APSR. {globalT("tous.droit")}
                <a href="https://mintransports.cm" target={"_blank"}>
                  {globalT("ministere")}
                </a>
              </span>
            </div>
          </div>
          <div className="col-md-5 text-end">
            <div className="d-flex justify-content-end">
              <Button tag="a" isLink onClick={() => navigate("/terms")}>
                {globalT("termes")}
              </Button>
              <Button
                tag="a"
                isLink
                onClick={() => navigate("/confidentialite")}
              >
                {globalT("prive")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="homeChat z-index-10 d-flex cursor-pointer justify-content-center align-items-center"
        onClick={(e) => {
          setShowBot((prev) => !prev);
          e.preventDefault();
          e.stopPropagation();
          toggleIcon();
        }}
      >
        {isToggled ? (
          <Icon icon="Close" onClick={() => setShowBot2(false)} size="4x" color="light" role="button"></Icon>
        ) : (
          <img src={chat} alt="chat" className="image" />
        )}
      </div>

      <div className="App">
        {showBot && (
          <>
            <div
              className="app-chatbot-container cursor-pointer bg-primary bounce-animation mx-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowBot2((prev) => !prev);
              }}
            >
               <span className="fs-4 cursor-pointer ps-1 mt-3 me-2">
                Chat-bot
              </span>
              <Icon
                icon="Android"
                size="4x"
                color="danger"
                role="button"
              ></Icon>
            </div>

            <div className="app-chatbot-containers cursor-pointer bg-primary bounce-animation mx-2"  onClick={() => navigate("/Chat")}>
              <span className="ps-1 fs-4 cursor-pointer mt-3 me-3">
                Forum
              </span>
              <Icon
                icon="Forum"
                size="4x"
                color="danger"
                role="button"
              ></Icon>
            </div>
          </>
        )}
      </div>

      <div className="App">
        {showBot2 && (
          <>
            <div
              className=" mx-2"
              style={{
                position: "fixed",
                transform:"translate(-50%, -50%)",
                top: "50%",
                zIndex: "99999",
                left: "50%",
              }}
            >
              <Chatbot
                config={config}
                actionProvider={ActionProvider}
                messageParser={MessageParser}
              />
            </div>

            {/*  <div className="app-chatbot-container mx-2">
              <Icon icon="Add" size="4x" color="primary" role="button"></Icon>
            </div>

            <div className="app-chatbot-containers" style={{}}>
              <Icon icon="Add" size="4x" color="primary" role="button"></Icon>
            </div> */}
          </>
        )}
      </div>
      <DialogComponent
        show={show}
        title={globalT("incident.add.title")}
        onClose={() => setShow(false)}
        size="lg"
      >
        <FrontAdd onClose={() => setShow(false)} />
      </DialogComponent>
      <DialogComponent
        show={showContact}
        title=""
        onClose={() => setShowContact(false)}
        size="lg"
      >
        <ContactAdd onClose={() => setShowContact(false)} />
      </DialogComponent>
      <DrawerComponent
        type={"right"}
        onclose={() => setShowInfPanel(false)}
        onOpen={() => setShowInfPanel(true)}
        data={null}
        label={globalT("detail.info")}
        open={showInfpanel}
      >
        <DetailFunc />
      </DrawerComponent>
    </div>
  );
};

export default Footer;
