import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import education from "../../../assets/img/landing2/nouv/education_page.jpg";
import newImage from "../../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../../components/ImageSensibilisation";

const Education = () => {
  useEffect(() => {
    window.scrollTo({
      top: 20,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header
        currentMenu={"education"}
        hasAutherImage={true}
        image={education}
        subtitle={"FORMATION (LISER)"}
        title={"EDUCATION"}
      />
      <div className="container" id={"sectionId"} style={{ marginBottom: "4rem" }}>
        <ImageSensibilisation
          image={newImage}
          texte="Education !!!"
        />{" "}
      </div>
      <br />
      <br />
      <div className="container">
        <div className="formation">
          <span className="formation__title">Nos derniers projets</span>
          <div className="d-flex g-3">
            <span className="">Tous</span>
            <span className="">2023</span>
            <span className="">2022</span>
            <span className="">2021</span>
            <span className="">2020</span>
          </div>
          <div className="row justify-content-center">
            <div className="col">
              <span className="rounded bg-light w-95 mx-auto d-block h-300">
                .
              </span>
            </div>
            <div className="col">
              <span className="rounded bg-light w-95 mx-auto d-block h-300">
                .
              </span>
            </div>
            <div className="col">
              <span className="rounded bg-light w-95 mx-auto d-block h-300">
                .
              </span>
            </div>
            <div className="col">
              <span className="rounded bg-light w-95 mx-auto d-block h-300">
                .
              </span>
            </div>
            <div className="col">
              <span className="rounded bg-light w-95 mx-auto d-block h-300">
                .
              </span>
            </div>
            <div className="col">
              <span className="rounded bg-light w-95 mx-auto d-block h-300">
                .
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Education;
