import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import "../Home.scss";
import "./services.scss";
import Forum from "../card/Forum";
import { globalT } from "../../../lang";
import Button from "../../../components/bootstrap/Button";
import { useNavigate } from "react-router-dom";
import LandingPage33 from "../../../assets/img/landing2/mint.jpg";
import newImage from "../../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../../components/ImageSensibilisation";

const Ong = () => {
  const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 20,
            behavior: "smooth",
        });
    }, []);

  return (
    <>
      {/*<Header
        currentMenu={"service"}
        hasAutherImage={true}
        image={LandingPage33}
        subtitle={globalT("nav.service")}
        title={globalT("nav.service.demande")}
      />*/}
        <Header currentMenu={"service"} />
      <div className="container" id={"sectionId"} style={{ marginBottom: "4rem" }}>
        <ImageSensibilisation
          image={newImage}
          texte={ `${globalT("ong.text")} !!!`}
        />
      </div>
      <br />
      <br />
      <div className="container">
        <h1 className="d-flex justify-content-center align-items-center">
          <span>{globalT("monter.projet")}</span>
        </h1>
        <br />
        <p className="fs-5 text-justify">
          <span>{globalT("gamme.depliant")}</span>
        </p>
        <br />
        <p className="fs-5 text-justify">
          <span>{globalT("lire.depliant")}</span>
        </p>
      </div>
      <br /> <br />
      <div className="container">
        <h1 className="d-flex justify-content-center align-items-center">
          <span>{globalT("solliciter.mint")}</span>
        </h1>
        <br />
        <p className="fs-5 text-justify">
          <span>{globalT("message.poignant")}</span>
        </p>
        <br />
        <p className="fs-5 text-justify">
          <span>{globalT("affiche.image")}</span>
        </p>
      </div>
      <div className="forumChat">
        <div className="container d-flex justify-content-center">
          <div className="d-flex justify-content-between align-items-center cardForum band-margin-top">
            <span>{globalT("security.question")}</span>
            <Button
              type="button"
              className="btn btn-danger btn-lg ms-5 me-2"
              onClick={() => navigate("/Chat")}
            >
              <span>Forum</span>
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Ong;
