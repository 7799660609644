import React, { useEffect, useRef, useState } from "react";
import Navnav from "../../Navnav/Navnav";
import Footfoot from "../../Navnav/Footfoot";
import { globalT } from "../../../lang";
import VideoLanding from "../../../assets/img/landing2/VideoLanding.webp";
import SearchComponent from "../../../components/SearchComponent";
import CardComponent from "../../../components/CardComponent";
import DataImage from "../../../assets/img/landing2/vide.webp";
import PaginationComponent from "../../../components/PaginationComponent";
import mintClient from "../../../network";
import Documentation from "../../../commons/models/Documentation";
import * as yup from "yup";
import CategoryDoc from "../../../commons/models/CategorieDoc";
import Option from "../../../components/bootstrap/Option";
import CardVid from "../../../components/bootstrap/CardVid";
import Header from "../Header";
import Footer from "../Footer";
import newImage from "../../../assets/img/landing2/CAMERNEWS.png";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import FrontAdd from "../../Incident/incident/FrontAdd";
import DrawerComponent from "../../../components/DrawerComponent";
import DetailFunc from "../../../components/DetailFunc";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import logo from "../../../assets/img/landing2/nouv/logo3.png";
import logo2 from "../../../assets/img/landing2/nouv/mintlogo.png";
import intero from "../../../assets/img/intero.png";
import Tooltips from "../../../components/bootstrap/Tooltips";
import { LanguageDict } from "../../../commons/types";
import { setLanguage } from "../../../store/slices/settings/actions";
import Button from "../../../components/bootstrap/Button";
import LANG from "../../../lang/language";
import Nav, { NavItem } from "../../../components/bootstrap/Nav";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../components/bootstrap/Dropdown";
import { joinUrlWithParamsId } from "../../../commons/helpers/funcHelper";
import { AUTH } from "../../../commons/urls/front";
import Backcopie from "../../../assets/img/landing2/nouv/back_copie.png";
import {MenuItem, Select} from "@mui/material";


interface OptionType {
  label: string;
  value: string;
  id: number;
}

interface IFormInput {
  categorie: OptionType | null;
}

const schema = yup.object().shape({
  categorie: yup.object().nullable().required("This field is required"),
});

type headerProps = {
  currentMenu?:
    | "acceuil"
    | "actualite"
    | "about"
    | "presentation"
    | "analyse"
    | "revue"
    | "service"
    | "education";
  hasAutherImage?: boolean;
  image?: string;
  title?: string;
  subtitle?: string;
};

const VideoEducation = ({
  currentMenu = "acceuil",
  hasAutherImage = false,
  image,
  title,
  subtitle,
}: headerProps) => {
  const [page, setPage] = useState(1);
  const total = useRef<number>();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("1");
  const [documentationVideo, setDocumentationVideo] = useState<
    Documentation[] | null
  >(null);
  const [categorie, setCategorie] = useState<CategoryDoc[] | null>(null);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const sectionRef = React.useRef<HTMLDivElement | null>(null);
  const scrollToSection = () => {
    const section = sectionRef.current;
    if (section) {
      const sectionTop =
        section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: sectionTop, behavior: "smooth" });
    }
  };

  const [active, setActive] = useState<
    | "acceuil"
    | "actualite"
    | "presentation"
    | "about"
    | "analyse"
    | "revue"
    | "service"
    | "education"
  >(currentMenu);

  const [show, setShow] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showInfpanel, setShowInfPanel] = useState<boolean>(false);
  const [showMobile, setShowMobile] = useState<boolean>(false);

  const { catActualite } = useAppSelector(({ catActualite }) => catActualite);
  const educationCat = useAppSelector(({ catRevue }) => catRevue);

  const dispatch = useAppDispatch();

  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );

  const changeLanguage = (locale: LanguageDict) => {
    if (locale !== settings.language) {
      dispatch(setLanguage(locale));
    }
  };

  const loadDocumentationVideo = () => {
    setLoading(true);
    // dispatch(setRequestGlobalLoader(true))
    mintClient.documentation
      .getAll({
        page: page,
        pageSize: 6,
        order: true,
        orderDirection: "ascending",
        validationState: 1,
        researchValue: search,
        hasPagination: true,
        categoryId: selectedCategoryId,
        isVideo: true,
      })
      .then((resp) => {
        const allDocumentationsVideos = resp.data.data;
        total.current = resp.data.total;
        setDocumentationVideo(allDocumentationsVideos);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const loadCategorie = () => {
    mintClient.category
      .getAll({ isParent: true, parentId: 1, page: 1, pageSize: 100 })
      .then((resp) => {
        setCategorie(resp.data.data);
      })
      .catch(() => null);
  };

  useEffect(() => {
    window.scrollTo({
      top: 20,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (!categorie) loadCategorie();
    loadDocumentationVideo();
  }, [selectedCategoryId, search, page]);

  const handleSearch = (value: any) => {
    setDocumentationVideo(null);
    setSearch(value);
    setPage(1);
  };

  return (
    <>
      <Header currentMenu={"education"} />
      <div className="container" id={"sectionId"}>

      </div>
      <div className="container section-11" style={{ marginTop: "50px" }}>
        {/* First section */}
        <div className="row mb-4" style={{ marginTop: "20px" }}>
          {/* Code for the first section */}
          <div className="col-md-5">
            <h1
              className="mb-3"
              style={{
                fontSize: "38px",
                marginBottom: "20px",
                fontWeight: "blod",
              }}
            >
              {globalT("edu.video.para.1")}
            </h1>
            <p style={{ fontSize: "17px", marginBottom: "20px" }}>
              {globalT("edu.video.para.2")}
            </p>
            <button
              type="button"
              className="btn btn-danger btn-lg"
              style={{ marginTop: "20px", backgroundColor: "#FFA500" }}
              onClick={scrollToSection}
            >
              <i
                className="fas fa-video"
                style={{ marginRight: "10px", color: "white" }}
              ></i>
              {globalT("landing.header.video")}
            </button>
          </div>
          <div className="col-md-6">
            <img
              src={VideoLanding}
              alt="Your Image"
              style={{ height: "500px", width: "800px" }}
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div
        className="container-fluid section-2"
        style={{ marginTop: "20px", backgroundColor: "#e5eae4" }}
      >
        <div
          className="container section-2"
          style={{ marginTop: "80px", marginBottom: "100px" }}
        >
          <h1 style={{ marginBottom: "10px", fontWeight: "blod" }}>
            {globalT("edu.video.header.card.1")}
          </h1>
          <p style={{ fontSize: "16px", marginBottom: "50px" }}>
            {globalT("edu.video.hearder.card.2")}
          </p>
          <div className="row">
            <div className="col-sm">
              <CardComponent
                title={globalT("edu.video.card.title.1")}
                description={globalT("edu.video.card.desc.1")}
                icon="fas fa-car"
              />
            </div>
            <div className="col-sm">
              <CardComponent
                title={globalT("edu.video.card.title.2")}
                description={globalT("edu.video.card.desc.2")}
                icon="fas fa-road"
              />
            </div>
            <div className="col-sm">
              <CardComponent
                title={globalT("edu.video.card.title.3")}
                description={globalT("edu.video.card.desc.3")}
                icon="fas fa-first-aid"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="container section-3"
        ref={sectionRef}
        style={{ marginTop: "80px" }}
      >
        <div
          style={{
            marginTop: "50px",
            height: "3px",
            background: "linear-gradient(to left , yellow 80%, red 20%)",
          }}
          className="mb-4"
        ></div>
        

        <div className="row mb-4 ">
          <div className="col-md-7">
            {/*<h2 className="mb-4">{globalT("educ.research.video")}</h2>*/}
          </div>
          <div className="col-md-5 d-flex row mb-4">
            {/* Replace with your search component */}
            <div className="col-md-6 mt-10">
              <p className="fw-bold" style={{ marginLeft: "40px" }}>
                {globalT("educ.research.vid")}
              </p>
              <SearchComponent onSearch={handleSearch} />
            </div>
            {/* Directly place SelectFromRemote component here */}
            <div className="col-md-5 ms-3">
              {categorie ? (
                <div className="d-flex flex-column">
                  <span className="text-start fw-bold mb-3">
                    {globalT("video.category")}
                  </span>
                  <Select
                      sx={{
                        width: 200,
                        height: 38,
                        borderRadius: 2,
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'black',
                        },
                        '&:hover': {
                          borderColor: 'orange',
                        }
                        ,
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'orange',
                        }

                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select-error"
                      value={selectedCategoryId}
                      label="Catégorie"
                      onChange={(e: { target: { value: string } }) => {
                        setDocumentationVideo(null);
                        setSelectedCategoryId(e.target.value);
                      }}
                  >
                    <MenuItem value={"1"}>Tous</MenuItem>
                    {categorie.map((cat) => (
                        <MenuItem
                            sx={{
                              '&:hover': {
                                backgroundColor: 'orange', // La couleur de fond au survol
                              },
                            }}
                            value={cat.id}>{cat.name}</MenuItem>

                    ))}
                  </Select>

                </div>
              ) : (
                <div
                  className="spinner-border"
                  style={{ height: "3rem", width: "3rem", color: "#aae0aa" }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row py-4">
          <>
            {loading && (
              <div className="d-flex justify-content-between">
                <span></span>
                <div
                  className="spinner-border"
                  style={{
                    height: "3rem",
                    width: "3rem",
                    color: "#aae0aa",
                    marginTop: "40px",
                    marginRight: "10px",
                  }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </>
          {documentationVideo && documentationVideo.length > 0 ? (
            <>
              {documentationVideo.map((doc, index) => (
                <div className="col-md-4 col-sm-12 mb-4 " id={`no${index}`}>
                  <CardVid
                    thomails={doc.thumbnail}
                    title={doc.name}
                    titleEn={doc.nameEn}
                    images={[
                      { attachmentFile: doc.file, attachmentTypeName: "VIDEO" },
                    ]}
                    id={doc.id}
                    date={doc.createdAt}
                    hasAnotherAction={true}
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="imageContainer" style={{ marginTop: "100px" }}>
              <div className="text-center mt-5 mb-5">
                <div
                  style={{
                    width: "400px", // or any size you want
                    height: "400px", // make it a square
                    backgroundImage: `url(${DataImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    margin: "0 auto", // this will center the div if the parent div has a text-align: center
                  }}
                  className="image"
                />
                {loading ? (
                  <div className="d-flex justify-content-center spinnerContainer">
                    <div
                      className="spinner-border"
                      style={{
                        height: "6rem",
                        width: "6rem",
                        color: "#aae0aa",
                        marginTop: "85px",
                        marginRight: "15px",
                      }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <span
                    className="fw-bold"
                    style={{
                      marginTop: "20px",
                      fontSize: "24px",
                    }}
                  >
                    {globalT("no.vidoe.available")}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <PaginationComponent
          pages={Math.ceil((total.current || 0) / 8)}
          currentPage={page}
          onPageChange={(page) => setPage(page)}
          rangeLimit={5}
        />
      </div>

      <Footer />
    </>
  );
};
export default VideoEducation;
