import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import EducationImg from "../../../assets/img/landing2/Education.webp";
import V1 from "../../../assets/img/landing2/infracts.gif";
import V2 from "../../../assets/img/landing2/revuDoc1.png";
import V3 from "../../../assets/img/landing2/stats.gif";
import V4 from "../../../assets/img/landing2/Process.gif";
import V5 from "../../../assets/img/landing2/Agreement.gif";
import V6 from "../../../assets/img/landing2/reglementation.jpg";
import V7 from "../../../assets/img/landing2/reglementation.jpg";
import DataImage from "../../../assets/img/landing2/vide.webp";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import mintClient from "../../../network";
import { globalT } from "../../../lang";
import "./revu.scss";
import { base64ToFile } from "../../../helpers/helpers";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import PaginationComponent from "../../../components/PaginationComponent";
import SearchComponent from "../../../components/SearchComponent";
import EncryptedPdfViewer from "../../../components/ReadPdf";
import RevueDocumentaire from "../../../components/bootstrap/RevueDocumentaire";
import Navnav from "../../Navnav/Navnav";
import Footfoot from "../../Navnav/Footfoot";
import Spinner from "../../../components/bootstrap/Spinner";

import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../../components/bootstrap/Dropdown";
import Button from "../../../components/bootstrap/Button";
import { AUTH } from "../../../commons/urls/front";
import Select from "../../../components/bootstrap/forms/Select";
import Option from "../../../components/bootstrap/Option";
import CategoryDoc from "../../../commons/models/CategorieDoc";
import Tooltips from "../../../components/bootstrap/Tooltips";
import LANG from "../../../lang/language";
import Header from "../Header";
import Footer from "../Footer";
import CardDocument from "../card/CardDocument";
import newImage from "../../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../../components/ImageSensibilisation";

type CategoryImagesType = {
  [key: string]: any;
  // ... autres clés ...
};

const RevueCategories = () => {
  const categoryImages: CategoryImagesType = {
    "12": {
      img: V2,
      text: "v2.text",
    },
    "8": {
      img: V1,
      text: "v1.text",
    },
    "11": {
      img: V3,
      text: "v3.text",
    },
    "9": {
      img: V4,
      text: "v4.text",
    },
    "7": {
      img: V5,
      text: "v5.text",
    },
    "6": {
      img: V6,
      text: "v6.text",
    },
    "Règlementation et constitution": {
      img: V7,
      text: "v7.text",
    },
    // autres catégories...
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [documents, setDocuments] = useState<any[] | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [currentDoc, setCurrentDoc] = useState<string | null>(null);
  const location = useLocation();
  const categorieName = location.state?.categorie || "";
  const categorieId = useParams().id;
  const imageToDisplay = categoryImages[`${categorieId}`] || DataImage;
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    null
  );
  const [categorie, setCategorie] = useState<CategoryDoc[] | null>(null);
  const total = useRef<number>();
  const [loading, setLoading] = useState(true);
  const [loadingCat, setLoadingCat] = useState(false);
  const filter = useRef<{
    year: string;
    month: string;
    region: string;
  }>({
    year: "",
    month: "",
    region: "",
  });
  const { settings, authUserData } = useAppSelector(
    ({ settings, authUser }) => ({
      settings,
      authUserData: authUser.data,
    })
  );

  const numberYear = useRef(new Date().getFullYear() - 2017 + 1);

  const handleFilteredDocumentsPageChange = (page: number) => {
    setCurrentPage(page);
  };
  useEffect(() => {}, [categorieName]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    window.scrollTo({
      top: 20,
      behavior: "smooth",
    });
  }, [categorieId]);

  const loadDocumentation = () => {
    setLoading(true); // Start loading
    mintClient.documentation
      .getAll({
        page: currentPage,
        pageSize: 10,
        orderDirection: "ascending",
        researchValue: searchValue,
        hasPagination: true,
        isDocument: true,
        langue: "fr",
        categoryId: selectedCategoryId
          ? selectedCategoryId
          : (categorieId as string),
      })
      .then((resp) => {
        const allDocuments = resp.data.data;
        total.current = resp.data.total;
        setDocuments(allDocuments);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const loadCategorie = () => {
    setLoadingCat(true);
    mintClient.category
      .getAll({
        isParent: true,
        parentId: categorieId as string,
        page: 1,
        pageSize: 100,
      })
      .then((resp) => {
        setCategorie(resp.data.data);
        setLoadingCat(false);
      })
      .catch(() => null);
  };

  useEffect(() => {
    loadCategorie();
    loadDocumentation();
  }, [currentPage, searchValue, categorieId, selectedCategoryId]);

  const downloadFile = (doc: any) => {
    const blob = base64ToFile(doc?.file as string, "application/pdf");

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = "receipt_file.pdf";
    link.style.display = "none";
    link.click();
    link.remove();
  };
  const handleDocumentClick = (file: string) => {
    setCurrentDoc(file);
    setShowPdf(true);
  };
  useEffect(() => {}, [categorieName]);

  const defaultYear = () => {
    let tabYear = [];
    for (let i = 1; i <= numberYear.current; i++) {
      tabYear.push(2024 - i);
    }
    return tabYear;
  };

  return (
    <>
      <Header currentMenu={"revue"} />
      <div className="container" id={"sectionId"}>
        <ImageSensibilisation
          image={newImage}
          texte="Révues des catégories !!!"
        />{" "}
      </div>
      <div className="container " style={{ marginTop: "150px" }}>
        <div className="d-flex">
          <h1 className="mb-4" style={{ fontWeight: "bold" }}>
            {globalT("education.management.revue")}:{" "}
          </h1>
          <h2 className="IO " style={{ fontWeight: "bold" }}>
            {`  ${
              settings.language === LANG.fr.locale
                ? categorieName.fr
                : categorieName.en
                ? categorieName.en
                : categorieName.fr
            }`}
          </h2>
        </div>

        <div className="row mb-4" style={{ marginTop: "20px" }}>
          <div className="col-md-9">
            {/*<h2 className="mb-3">{globalT("edu.h3.pa")}</h2>*/}
            <p className="h-100 d-flex align-items-center" style={{ fontSize: "17px" }}>{globalT(imageToDisplay.text)}</p>
          </div>
          <div className="col-md-3">
            <img
              src={imageToDisplay.img}
              alt={categorieName}
              style={{ height: "300px", width: "100%" }}
              className="img-fluid"
            />
          </div>
        </div>

        <div
          style={{
            marginTop: "50px",
            height: "3px",
            background: "linear-gradient(to left , yellow 80%, red 20%)",
          }}
          className="mb-4"
        ></div>

        <div className="row mb-4">
          <div className="col-md-6">
            {/*<h2 className="mb-3">{globalT("educ.research")}</h2>*/}
          </div>
          <div className="col-md-6">
            {categorieId === "12" ? (
              <div className="row">
                <Tooltips title={globalT("year.session.region")}>
                  <div className="col-md-5">
                    <p className="fw-bold" style={{ marginLeft: "40px" }}>
                      {globalT("educ.research.vid")}
                    </p>
                    <SearchComponent onSearch={handleSearch} />
                  </div>
                </Tooltips>

                <div className="col-md-3">
                  <span className="text-start fw-bold mb-3">
                    {globalT("video.category")}
                  </span>
                  <Dropdown canUseClick={true} direction="end">
                    <DropdownToggle>
                      <Button>
                        <span>{globalT("filter.year")}</span>
                      </Button>
                    </DropdownToggle>
                    <DropdownMenu>
                      {defaultYear().map((elt) => (
                        <DropdownItem>
                          <Dropdown direction="end">
                            <DropdownToggle>
                              <Button
                                onClick={() => (filter.current.year = `${elt}`)}
                              >
                                <span>{elt}</span>
                              </Button>
                            </DropdownToggle>
                            <DropdownMenu direction="end">
                              <DropdownItem>
                                <Dropdown direction="end">
                                  <DropdownToggle>
                                    <Button
                                      onClick={() =>
                                        (filter.current.month = "janvier")
                                      }
                                    >
                                      <span>{globalT("doc.month.1")}</span>
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu direction="end">
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "EN";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.1")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "AD";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.2")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "ES";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.3")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "CE";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.4")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.5")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NO";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.6")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "LT";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.7")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "OU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.8")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.9")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.10")}
                                      </Button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </DropdownItem>
                              <DropdownItem>
                                <Dropdown direction="end">
                                  <DropdownToggle>
                                    <Button
                                      onClick={() =>
                                        (filter.current.month = "février")
                                      }
                                    >
                                      <span>{globalT("doc.month.2")}</span>
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu direction="end">
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "EN";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.1")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "AD";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.2")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "ES";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.3")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "CE";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.4")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.5")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NO";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.6")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "LT";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.7")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "OU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.8")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.9")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.10")}
                                      </Button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </DropdownItem>
                              <DropdownItem>
                                <Dropdown direction="end">
                                  <DropdownToggle>
                                    <Button
                                      onClick={() =>
                                        (filter.current.month = "mars")
                                      }
                                    >
                                      <span>{globalT("doc.month.3")}</span>
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu direction="end">
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "EN";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.1")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "AD";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.2")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "ES";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.3")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "CE";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.4")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.5")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NO";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.6")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "LT";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.7")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "OU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.8")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.9")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.10")}
                                      </Button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </DropdownItem>
                              <DropdownItem>
                                <Dropdown direction="end">
                                  <DropdownToggle>
                                    <Button
                                      onClick={() =>
                                        (filter.current.month = "avril")
                                      }
                                    >
                                      <span>{globalT("doc.month.4")}</span>
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu direction="end">
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "EN";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.1")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "AD";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.2")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "ES";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.3")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "CE";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.4")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.5")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NO";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.6")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "LT";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.7")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "OU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.8")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.9")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.10")}
                                      </Button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </DropdownItem>
                              <DropdownItem>
                                <Dropdown direction="end">
                                  <DropdownToggle>
                                    <Button
                                      onClick={() =>
                                        (filter.current.month = "mai")
                                      }
                                    >
                                      <span>{globalT("doc.month.5")}</span>
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu direction="end">
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "EN";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.1")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "AD";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.2")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "ES";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.3")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "CE";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.4")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.5")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NO";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.6")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "LT";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.7")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "OU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.8")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.9")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.10")}
                                      </Button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </DropdownItem>
                              <DropdownItem>
                                <Dropdown direction="end">
                                  <DropdownToggle>
                                    <Button
                                      onClick={() =>
                                        (filter.current.month = "juin")
                                      }
                                    >
                                      <span>{globalT("doc.month.6")}</span>
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu direction="end">
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "EN";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.1")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "AD";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.2")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "ES";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.3")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "CE";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.4")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.5")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NO";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.6")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "LT";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.7")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "OU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.8")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.9")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.10")}
                                      </Button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </DropdownItem>
                              <DropdownItem>
                                <Dropdown direction="end">
                                  <DropdownToggle>
                                    <Button
                                      onClick={() =>
                                        (filter.current.month = "juillet")
                                      }
                                    >
                                      <span>{globalT("doc.month.7")}</span>
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu direction="end">
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "EN";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.1")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "AD";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.2")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "ES";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.3")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "CE";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.4")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.5")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NO";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.6")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "LT";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.7")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "OU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.8")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.9")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.10")}
                                      </Button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </DropdownItem>
                              <DropdownItem>
                                <Dropdown direction="end">
                                  <DropdownToggle>
                                    <Button
                                      onClick={() =>
                                        (filter.current.month = "août")
                                      }
                                    >
                                      <span>{globalT("doc.month.8")}</span>
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu direction="end">
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "EN";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.1")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "AD";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.2")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "ES";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.3")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "CE";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.4")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.5")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NO";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.6")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "LT";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.7")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "OU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.8")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.9")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.10")}
                                      </Button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </DropdownItem>
                              <DropdownItem>
                                <Dropdown direction="end">
                                  <DropdownToggle>
                                    <Button
                                      onClick={() =>
                                        (filter.current.month = "septembre")
                                      }
                                    >
                                      <span>{globalT("doc.month.9")}</span>
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu direction="end">
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "EN";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.1")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "AD";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.2")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "ES";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.3")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "CE";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.4")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.5")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NO";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.6")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "LT";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.7")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "OU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.8")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.9")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.10")}
                                      </Button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </DropdownItem>
                              <DropdownItem>
                                <Dropdown direction="end">
                                  <DropdownToggle>
                                    <Button
                                      onClick={() =>
                                        (filter.current.month = "octobre")
                                      }
                                    >
                                      <span>{globalT("doc.month.10")}</span>
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu direction="end">
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "EN";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.1")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "AD";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.2")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "ES";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.3")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "CE";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.4")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.5")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NO";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.6")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "LT";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.7")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "OU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.8")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.9")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.10")}
                                      </Button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </DropdownItem>
                              <DropdownItem>
                                <Dropdown direction="end">
                                  <DropdownToggle>
                                    <Button
                                      onClick={() =>
                                        (filter.current.month = "novembre")
                                      }
                                    >
                                      <span>{globalT("doc.month.11")}</span>
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu direction="end">
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "EN";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.1")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "AD";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.2")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "ES";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.3")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "CE";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.4")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.5")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NO";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.6")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "LT";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.7")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "OU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.8")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.9")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.10")}
                                      </Button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </DropdownItem>
                              <DropdownItem>
                                <Dropdown direction="end">
                                  <DropdownToggle>
                                    <Button
                                      onClick={() =>
                                        (filter.current.month = "decembre")
                                      }
                                    >
                                      <span>{globalT("doc.month.12")}</span>
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu direction="end">
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "EN";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.1")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "AD";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.2")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "ES";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.3")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "CE";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.4")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.5")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NO";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.6")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "LT";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.7")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "OU";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.8")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "NW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.9")}
                                      </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <Button
                                        onClick={() => {
                                          filter.current.region = "SW";
                                          setSearchValue(
                                            `${filter.current.month} ${filter.current.year} ${filter.current.region}`
                                          );
                                        }}
                                      >
                                        {globalT("doc.region.10")}
                                      </Button>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn fv btn-sm"
                    onClick={() => setSearchValue("")}
                  >
                    {globalT("button.reset")}
                  </button>
                  {/*<Button color='success' onClick={() => setSearchValue('')}>{globalT('button.reset')}</Button>*/}
                </div>
              </div>
            ) : (
              <div className="row mb-4">
                {/* Replace with your search component */}
                <div className="col-md-6 mt-10">
                  <p className="fw-bold" style={{ marginLeft: "40px" }}>
                    {globalT("educ.research.vid")}
                  </p>
                  <SearchComponent onSearch={handleSearch} />
                </div>
                {/* Directly place SelectFromRemote component here */}
                <div className="col-md-5 ms-3">
                  {categorie && categorie?.length > 0 && (
                    <div className="d-flex flex-column">
                      <span className="text-start fw-bold mb-3">
                        {globalT("video.category")}
                      </span>
                      <Select
                        size="sm"
                        ariaLabel="Per"
                        onChange={(e: { target: { value: string } }) => {
                          setSelectedCategoryId(e.target.value);
                        }}
                        value={selectedCategoryId || ""}
                      >
                        <Option key="all" value="1">
                          tous
                        </Option>

                        {categorie.map((cat) => (
                          <Option key={cat.id} value={cat.id}>
                            {cat.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                  {loadingCat && (
                    <div
                      className="spinner-border"
                      style={{
                        height: "3rem",
                        width: "3rem",
                        color: "#aae0aa",
                      }}
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {documents ? (
          <></>
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "200px" }}
          >
            <div
              className="spinner-border"
              style={{ height: "6rem", width: "6rem", color: "#aae0aa" }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <section className="container pt-3">
          <div className="row py-4">
            {documents && documents.length > 0 ? (
              <>
                <div className="d-flex justify-content-between mb-4">
                  <span></span>
                  {loading && <Spinner size="3rem" customColor="#aae0aa" />}
                </div>
                <div className="row">
                  {documents.map((pub) => (
                    <div className="col-4 mb-2">
                      <CardDocument
                        id={pub.id}
                        name={pub.name}
                        nameEn={pub.nameEn}
                        mp3={pub.file}
                        onClick={() => handleDocumentClick(pub.file)}
                        createAt={pub.createdAt}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="text-center mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                  <div
                    style={{
                      width: "400px", // or any size you want
                      height: "400px", // make it a square
                      backgroundImage: `url(${DataImage})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      margin: "0 auto", // this will center the div if the parent div has a text-align: center
                    }}
                  />
                  <span
                    className="fw-bold"
                    style={{
                      display: "block",
                      marginTop: "20px",
                      fontSize: "24px",
                    }}
                  >
                    {globalT("custum.list.empty")}
                  </span>
                </div>
              </div>
            )}
          </div>

          {showPdf && currentDoc && (
            <DialogComponent
              show={showPdf}
              size="lg"
              title=""
              onClose={() => setShowPdf(false)}
            >
              <EncryptedPdfViewer base64EncryptedPdf={currentDoc} />
            </DialogComponent>
          )}
          <PaginationComponent
            pages={Math.ceil((total.current || 0) / 6)}
            currentPage={currentPage}
            onPageChange={handleFilteredDocumentsPageChange}
            rangeLimit={5}
          />
        </section>
      </div>
      <Footer />
    </>
  );
};

export default RevueCategories;
