import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { MessageDiscusion } from "../../../commons/models/Message";
import Navnav from "../../Navnav/Navnav";
import Page from "../../../layout/PageWrapper/Page";
import Input from "../../../components/bootstrap/forms/Input";
import Spinner from "../../../components/bootstrap/Spinner";
import PaginationComponent from "../../../components/PaginationComponent";
import { SubmitHandler, useForm } from "react-hook-form";
import csuClient from "../../../network";
import NotificationManager from "../../../components/notifications/NotificationManager";
import InputComponent from "../../../components/forms/InputComponent";
import IntlMessages from "../../../components/IntlMessages";
import Icon from "../../../components/icon/Icon";
import Button from "../../../components/bootstrap/Button";
import dayjs, { Dayjs } from "dayjs";
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../../components/bootstrap/Dropdown";
import { useAppDispatch, useAppSelector } from "../../../store/redux.types";
import DialogComponent from "../../../components/dialogs/DialogComponent";
import { globalT } from "../../../lang";
import * as yupSchema from "../../../components/forms/yupShema";
import { messagePhone } from "../../../store/slices/MessagePhone/actions";
import { createUserWithEmail } from "../../../components/Firebase";
import {
  doc,
  setDoc,
  addDoc,
  collection,
  onSnapshot,
  query,
  orderBy,
  where,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { database } from "../../../config/firebaseConf";
import Message from "./message/Message";
import StorageHelper, {
  StorageKeys,
} from "../../../commons/helpers/StorageHelper";
import Header from "../Header";
import Footer from "../Footer";
import newImage from "../../../assets/img/landing2/back_copie.png";
import ImageSensibilisation from "../../../components/ImageSensibilisation";

type formatData = {
  _id: string;
  createdAt: Date;
  text: string;
  user: {
    _id: string;
    name: string;
  };
};
const DiscusionMessage = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
  });

  const location = useLocation();

  const categirieName = location.state.categorieName;
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const discussionId = useParams().id;
  const total = useRef<number>();
  const [file, setFile] = useState<any | null>(null);
  const messagePhoneUser = useAppSelector(
    ({ messagePhone }) => messagePhone.messagePhone
  );
  const [showFormPhone, setShowForm] = useState(false);
  const dispatch = useAppDispatch();
  const [allMessages, setAllMessages] = useState<formatData[] | null>(null);
  const [messageValue, setMessageValue] = useState("");
  const monInput = document.getElementById("myInput");
  const isAdministrator = useRef(false);

  useEffect(() => {
    setLoading(true);
    const q = query(
      collection(database, `discussion_${discussionId}`),
      orderBy("createdAt", "asc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages: any[] = [];
      querySnapshot.forEach((doc) => {
        const data: formatData = {
          _id: doc.data()._id,
          createdAt: doc.data().createdAt,
          text: doc.data().text,
          user: {
            _id: doc.data().user._id,
            name: doc.data().user.name,
          },
        };
        messages.push(data);
      });
      setAllMessages(messages);
      setLoading(false);
    });
    // return unsubscribe()
  }, []);

  const verifModerator = async (number: string) => {
    await csuClient.moderateur
      .activer(number)
      .then(() => {
        isAdministrator.current = true;
      })
      .catch((errors) => {
        if (errors.response.data.message === "Moderator already activated") {
          isAdministrator.current = true;
        } else isAdministrator.current = false;
      });
  };

  const generateDocId = () => {
    const collectionRf = collection(database, `discussion_${discussionId}`);
    const docRef = doc(collectionRf);
    return docRef.id;
  };

  useLayoutEffect(() => {
    const dataUsager = JSON.parse(
      StorageHelper.getItem(StorageKeys.USER_USAGER) as string
    );
    console.log("useger :", dataUsager);
    dispatch(
      messagePhone({
        phone: dataUsager.phone,
        name: dataUsager.name,
        lastName: dataUsager.lastName,
        isAdmin: dataUsager.isAdmin,
      })
    );
  }, []);

  const onSubmit: SubmitHandler<any> = async (data) => {
    setShowForm(false);
    const idDoc = await generateDocId();

    let dataFirebase: formatData = {
      _id: idDoc,
      createdAt: new Date(),
      text: messageValue as string,
      user: {
        _id: messagePhoneUser?.isAdmin
          ? `1${messagePhoneUser?.phone}`
          : `0${messagePhoneUser?.phone}`,
        name: messagePhoneUser ? messagePhoneUser.name : data.nameUser,
        // isAdmin: messagePhoneUser ? messagePhoneUser.isAdmin : isAdministrator.current,
      },
    };

    await addDoc(
      collection(database, `discussion_${discussionId}`),
      dataFirebase
    );
    setMessageValue("");
  };

  const onChangeFile = (e: any) => {
    setFile(e.target.files[0]);
  };

  const deleteElement = async (id: string) => {
    const q = query(
      collection(database, `discussion_${discussionId}`),
      where("_id", "==", id)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docs) => {
      deleteDoc(docs.ref);
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 20,
      behavior: "smooth",
    });
  }, []);

  const repondElement = (name: string) => {
    monInput?.focus();
    setMessageValue(`@${name.split(" ").join("_")} `);
  };

  return (
    <>
      <Header currentMenu={"acceuil"} />
      <div className="container" id={"sectionId"}>
        <ImageSensibilisation
          image={newImage}
          texte="Discussion et Messages !!!"
        />{" "}
      </div>

      <Page>
        <div className="discu-container discu-header">
          <div className="chat-content">
            <div className="container text-center">
              <div className="row">
                <div className="col-11">
                  <div className="row">
                    <span className="fw-bold display-6 mb-4 mt-3">
                      {categirieName}
                    </span>
                    {/*<span className='ms-3 mb-4 chat-infoos'>Ce sujet contient { total.current || totalMessage } messages</span>*/}
                    <div className="col-2"></div>
                    <div className="col-10 text-start">
                      <div>
                        {allMessages && allMessages.length > 0 && (
                          <>
                            {allMessages.map((d) => (
                              <Message
                                idDoc={d._id}
                                phone={d.user._id}
                                isAdmin={
                                  d.user._id.startsWith("1") ? true : false
                                }
                                message={d.text}
                                date={d.createdAt}
                                nom={d.user.name}
                                repondElement={repondElement}
                                deleteElement={deleteElement}
                              />
                            ))}
                          </>
                        )}
                      </div>
                      <div className="text-end">
                        {loading && <Spinner color="primary" size="3rem" />}{" "}
                        <br />
                      </div>

                      <div className="chatMessage">
                        <form className="" onSubmit={handleSubmit(onSubmit)}>
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-1"></div>
                                {/*<div className="col-1">*/}
                                {/*    <Dropdown>*/}
                                {/*        <DropdownToggle>*/}
                                {/*            <Icon icon='Add' size='4x' color='info' role="button"></Icon>*/}
                                {/*        </DropdownToggle>*/}
                                {/*        <DropdownMenu>*/}
                                {/*            <DropdownItem>*/}
                                {/*                <div>*/}
                                {/*                    <label htmlFor="image_uploads"><Icon icon='Image'/>Image ou video</label>*/}
                                {/*                    <input*/}
                                {/*                        className='opacity-0'*/}
                                {/*                        type="file"*/}
                                {/*                        onChange={onChangeFile}*/}
                                {/*                        id="image_uploads"*/}
                                {/*                        name="image_uploads"*/}
                                {/*                        accept="image/*, video/*"*/}
                                {/*                        multiple*/}
                                {/*                    />*/}
                                {/*                </div>*/}
                                {/*            </DropdownItem>*/}
                                {/*        </DropdownMenu>*/}
                                {/*    </Dropdown>*/}
                                {/*    <Icon icon='Delete'*/}
                                {/*          role='button'*/}
                                {/*          size='4x'*/}
                                {/*          className='text-info mt-2 fw-bold '*/}
                                {/*          onClick={() => setFile(null)}*/}
                                {/*    />*/}
                                {/*    /!*<Button*!/*/}
                                {/*    /!*    icon='Add'*!/*/}
                                {/*    /!*    onClick={() => console.log('data')}*!/*/}
                                {/*    /!*    color='info'*!/*/}
                                {/*    /!*>*!/*/}

                                {/*    /!*</Button>*!/*/}
                                {/*</div>*/}
                                <div className="col-11">
                                  <div className="row">
                                    <div className="col-11">
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          id="myInput"
                                          value={messageValue}
                                          onChange={(e) =>
                                            setMessageValue(e.target.value)
                                          }
                                          className="form-control"
                                          aria-label="Sizing example input"
                                          aria-describedby="inputGroup-sizing-default"
                                          required={true}
                                        />
                                      </div>
                                      {/*<InputComponent*/}
                                      {/*    id='inputElement'*/}
                                      {/*    name="name"*/}
                                      {/*    componentType='textarea'*/}
                                      {/*    errors={errors}*/}
                                      {/*    control={control}*/}
                                      {/*    isRequired={true}*/}
                                      {/*    // label={<IntlMessages id='form.field.name'/>}*/}
                                      {/*    helperLabel={'Votre message'}*/}
                                      {/*/>*/}
                                    </div>
                                    {file && (
                                      <div className="col-1">
                                        {file.type.includes("image") ? (
                                          <img
                                            src={window.URL.createObjectURL(
                                              file
                                            )}
                                            alt=""
                                            className="img-fluid"
                                          />
                                        ) : file.type.includes("video") ? (
                                          <video controls className="img-fluid">
                                            <source
                                              src={window.URL.createObjectURL(
                                                file
                                              )}
                                              type="video/mp4"
                                            />
                                            Votre navigateur ne prend pas en
                                            charge les vidéos HTML5.
                                          </video>
                                        ) : (
                                          <span className="text-center fw-bold">
                                            Fichier non valide; veillez choisir
                                            une video ou une image
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {messagePhoneUser ? (
                            <div className="text-end mt-3">
                              <Button
                                icon="Save"
                                type="submit"
                                color="success"
                                // iconPosition='right'
                                className="text-nowrap bg-gradient me-5 ms-2"
                              >
                                <IntlMessages id="button.send" />
                              </Button>
                            </div>
                          ) : (
                            <div className="text-end mt-3 me-5">
                              <Button
                                icon="Save"
                                type="button"
                                color="info"
                                onClick={() => setShowForm(true)}
                                // iconPosition='right'
                                className="text-nowrap bg-gradient ms-2"
                              >
                                <IntlMessages id="button.send" />
                              </Button>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
            </div>
          </div>

          {/*<PaginationComponent pages={Math.ceil((total.current || 0) / 10)} currentPage={page} onPageChange={(page) => setPage(page)} rangeLimit={5} />*/}
        </div>
        <DialogComponent
          show={showFormPhone}
          title={globalT("form.forum.message.phone")}
          onClose={() => setShowForm(false)}
        >
          <form onClick={handleSubmit(onSubmit)}>
            <InputComponent
              errors={errors}
              control={control}
              displayRequiredAsterisk
              isRequired={true}
              name="nameUser"
              label={<IntlMessages id="form.field.lastName" />}
            />
            <InputComponent
              errors={errors}
              control={control}
              displayRequiredAsterisk
              type="number"
              isRequired={true}
              name="phone"
              label={<IntlMessages id="form.field.phoneNumber" />}
              otherValidators={{
                validate: (val: string | undefined) =>
                  !val
                    ? true
                    : yupSchema.regex.cameroonPhoneNumber.test(val || "") || (
                        <IntlMessages id="form.errors.phone.cmr" />
                      ),
              }}
            />
            <div className="text-end mt-3">
              <Button
                icon="Save"
                type="submit"
                color="info"
                // iconPosition='right'
                className="text-nowrap bg-gradient ms-2"
              >
                <IntlMessages id="button.save" />
              </Button>
            </div>
          </form>
        </DialogComponent>
      </Page>
      <Footer />
    </>
  );
};

export default DiscusionMessage;
