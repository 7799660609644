import { createChatBotMessage, createCustomMessage } from 'react-chatbot-kit';
import GlobalMessage from "./GlobalMessage";
import Question from "./otherQuestion/Question";
import Question1 from "./otherQuestion/Question1";
import Question2 from "./otherQuestion/Question2";
import Question3 from "./otherQuestion/Question3";
import AllResponse from "./otherQuestion/AllResponse";
import CustomResponse from "./otherQuestion/CustomResponse";
import {globalT} from "../../lang";
import 'react-chatbot-kit/build/main.css';
import "./chatbot.scss"


const config = {
    initialMessages: [createChatBotMessage(globalT('bot.intro.1')),
        createChatBotMessage(globalT('bot.intro.2')),
        createChatBotMessage(
            globalT('bot.choice.categorie'),
            {
                withAvatar: false,
                delay: 400,
                widget: "global",
                payload: 10,
                loading: true,
            }
        )
    ],
    widgets: [
        {
            widgetName: "question1",
            widgetFunc: (props) => <Question />,
            mapStateToProps: [],
            props: null,
        },
        {
            widgetName: "question2",
            widgetFunc: (props) => <Question1 />,
            mapStateToProps: [],
            props: null,
        },
        {
            widgetName: "question3",
            widgetFunc: (props) => <Question2 />,
            mapStateToProps: [],
            props: null,
        },
        {
            widgetName: "question4",
            widgetFunc: (props) => <Question3 />,
            mapStateToProps: [],
            props: {toto: 4},
        },
        {
            widgetName: "global",
            widgetFunc: (props) => <GlobalMessage {...props} />,
            mapStateToProps: ['gist', 'infoBox'],
            props: {toto: 4},

        },
        {
            widgetName: "response",
            widgetFunc: (props) => <AllResponse {...props} />,
            mapStateToProps: [],
            props: null,

        },
        {
            widgetName: "responseCustom",
            widgetFunc: (props) => <CustomResponse {...props} />,
            mapStateToProps: [],
            props: null,

        },
    ],
    botName: 'botName',

    customStyles: {
        chatInput: {
            backgroundColor: '#FA6400 !important',
            fontSize: '26px',
            borderRadius: '10px',
        },
        userMessageBox: {
            backgroundColor: '#4CAF50 !important', // Couleur des messages de l'utilisateur
        },
        botMessageBox: {
            backgroundColor: '#FA6400',
            color: 'red'
        },
        chatButton: {
            backgroundColor: '#F7B500',

        },
    },

};

export default config;